import React, {StrictMode} from 'react';
import './i18n';
import App from './App';
import Loader from './components/Loader'
import {Provider} from 'react-redux';
import {getStore} from './redux/store';
import {createRoot} from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StrictMode>
        <Provider store={getStore({})}>
            <React.Suspense fallback={<Loader/>}>
                <App/>
            </React.Suspense>
        </Provider>
    </StrictMode>
);