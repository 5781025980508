let MENU_ITEMS2 = [
    { key: 'mi-home', isTitle: false, icon: 'uil-home-alt', url: '/home' },
    { key: 'mi-trip-planner', isTitle: false, icon: 'mdi mdi-magnify', url: '/find' },
    {
        key: 'bottom_nav_trips',
        isTitle: false,
        icon: 'uil-calendar-alt',
        children: [
            { key: 'trips_textview_label_record_a_trip', url: '/trips/trip', parentKey: 'bottom_nav_trips' },
            { key: 'trips-syncing', label: 'Trip Syncing', url: '/trips/syncing', parentKey: 'bottom_nav_trips' },
            { key: 'trips_textview_label_trip_calendar', url: '/trips/calendar', parentKey: 'bottom_nav_trips' },
            { key: 'trips_textview_label_trip_profiles', label: 'Trip Profiles', url: '/trips/trip_profiles', parentKey: 'bottom_nav_trips' },
            { key: 'trips_textview_label_completed_trips', url: '/trips/trips', parentKey: 'bottom_nav_trips' },
            { key: 'trips_textview_label_points_and_redemptions', url: '/trips/points', parentKey: 'bottom_nav_trips' },
        ],
    },
    { key: 'bottom_nav_rewards', isTitle: false, icon: 'uil-gift', url: '/rewards', childPages: [`/reward/{id}`] },
    {
        key: 'me_textview_header_account',
        isTitle: false,
        icon: 'mdi mdi-account-circle-outline',
        children: [
            { key: 'me_textview_label_contact_info', url: '/account/contact_info', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_do_not_contact', url: '/account/do_not_contact', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_emails', url: '/account/emails', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_locations', url: '/account/locations', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_password', url: '/account/password', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_photo', url: '/account/photo', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_profile', url: '/account/profile', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_racing', url: '/account/racing', parentKey: 'me_textview_header_account', childPages: [`/race_team/{id}`, `/race_individual/{id}`] },
            { key: 'me_textview_label_supervisor', url: '/account/supervisor', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_vehicles', url: '/account/vehicles', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_volunteer', url: '/account/volunteer', parentKey: 'me_textview_header_account' },
            { key: 'me_textview_label_delete_account', url: '/account/delete_account', parentKey: 'me_textview_header_account' },
        ],
    },
    {
        key: 'bottom_nav_more',
        isTitle: false,
        icon: 'uil-ellipsis-h',
        children: [
            { key: 'more_textview_label_commute_calculator', url: '/more/commute_calculator', parentKey: 'bottom_nav_more' },
            { key: 'more_textview_label_faqs', url: '/more/faqs', parentKey: 'bottom_nav_more' },
            { key: 'more_textview_label_help', url: '/more/help', parentKey: 'bottom_nav_more' },
            { key: 'more_textview_label_contact_us', url: '/more/contact_us', parentKey: 'bottom_nav_more' },
        ],
    }
    ];

function addAboutUsToMenu(link) {
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children.splice(4,0, { key: 'more_textview_label_about_us', url: link, parentKey: 'bottom_nav_more', target: "_blank", isExternal: true });
}


const removeCobrandMenuItemOne = () => {
    let moreItems = MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children;
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children = moreItems.filter(item => item.id !== 'cobrand');
}

const addCobrandMenuItemOne = (name, id) => {
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children.splice(6,0, { key: name, id: 'cobrand', url: `/more/partner?partnerID=${id}`, parentKey: 'bottom_nav_more', cobrandID: id });
}

const addCobrandMenuItemTwo = (name, id) => {
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children.splice(7,0, { key: name, id: 'cobrand2', url: `/more/partner?partnerID=${id}`, parentKey: 'bottom_nav_more', cobrandID: id });
}

const removeCobrandMenuItemTwo = () => {
    let moreItems = MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children;
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children = moreItems.filter(item => item.id !== 'cobrand2');
}

const addSpecialEventToMenu = (title, id) => {
    if (!MENU_ITEMS2.find(item => item.key === title)) {
        //it hasn't already been added
        MENU_ITEMS2.push({ key: title, isTitle: false, icon: 'uil-award', url: `/event/${id}` })
    }
}

const addERHToMenu = (title, id) => {
    if (!MENU_ITEMS2.find(item => item.key === title)) {
        MENU_ITEMS2.push({key: title, isTitle: false, icon: 'uil-taxi', url: id > 0 ? `/rewards?rewardID=${id}` : `/rewards?category=200`});
    }
}

const  removeERHFromMenu = () => {
    MENU_ITEMS2 = MENU_ITEMS2.filter(item => !item.url || !item.url.includes("/rewards?"));
}

const  removeSpecialEventsFromMenu = () => {
    MENU_ITEMS2 = MENU_ITEMS2.filter(item => !item.url || !item.url.includes("/event/"));
}

const  removeAdminMenuItems = () => {
    MENU_ITEMS2 = MENU_ITEMS2.filter(item => item.key !== "mi-admin");
}

const addAdminMenuItems = (adminFeatures) => {

    let menuItem =     {
        key: 'mi-admin',
        isTitle: false,
        icon: 'dripicons-meter',
    }

    const menuChildren = [];

    adminFeatures.forEach(feature => {
        switch(feature) {
            case 13:
             menuChildren.push({ key: 'admin-org', url: '/admin/orgs', parentKey: 'mi-admin', childPages: [`/admin/org/{id}`]});
             //add more menu items in here as we create them
        }
    });

    if (menuChildren.length) {
        menuItem.children = menuChildren;
        MENU_ITEMS2.push(menuItem);
    }
}

function addAutoRecordedTrips() {
    MENU_ITEMS2.find(el=> el.key === 'bottom_nav_trips').children.splice(0,0, { key: 'trips_textview_label_auto_record_trips', url: '/trips/auto_record_trips', parentKey: 'bottom_nav_trips' });
}

function removeAutoRecordedTrips() {
    let tripItems = MENU_ITEMS2.find(el => el.key === 'bottom_nav_trips').children;
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_trips').children = tripItems.filter(item => item.key !== 'trips_textview_label_auto_record_trips');
}

function removeAboutUs() {
    let moreItems = MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children;
    MENU_ITEMS2.find(el => el.key === 'bottom_nav_more').children = moreItems.filter(item => item.key !== "more_textview_label_about_us");
}

function removeVolunteer() {
    let accountItems = MENU_ITEMS2.find(el => el.key === 'me_textview_header_account').children;
    MENU_ITEMS2.find(el => el.key === 'me_textview_header_account').children = accountItems.filter(item => item.key !== 'me_textview_label_volunteer');
}

function addVolunteer() {
    const children = MENU_ITEMS2.find(el => el.key === 'me_textview_header_account').children;
    children.splice(children.length - 1, 0,{ key: 'me_textview_label_volunteer', url: '/account/volunteer' })
}

export {
    MENU_ITEMS2,
    removeCobrandMenuItemTwo,
    removeCobrandMenuItemOne,
    addCobrandMenuItemTwo,
    addCobrandMenuItemOne,
    addSpecialEventToMenu,
    removeSpecialEventsFromMenu,
    addAdminMenuItems,
    removeAdminMenuItems,
    addAutoRecordedTrips,
    removeAutoRecordedTrips,
    addAboutUsToMenu,
    removeAboutUs,
    addERHToMenu,
    removeERHFromMenu,
    removeVolunteer,
    addVolunteer
};