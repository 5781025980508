import React, {useEffect, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import history from "../history";

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';

import {authProtectedFlattenRoutes, publicProtectedFlattenRoutes} from './index';
import ScrollToTop from "../components/ScrollToTop";
import {useTranslation} from "react-i18next";
import {memberLangChangeRequested, publicLangChangeRequested} from "../redux/langs/actions";
import Loader from "../components/Loader";
import {APIProvider} from "@vis.gl/react-google-maps";
import {GOOGLE_MAPS_LIBRARIES} from "../scripts/constants";
import {toggleTheme} from "../redux/layout/actions";

const _DEFAULT_GOOGLE_KEY = "AIzaSyA1Nxs3SCHyIoABW_CybHHFUynn0zKF8eo";
const iFrame = window.location.pathname === "/trip_planner_iframe";
const isAgileMileSite = document.referrer.includes("agilemile.com");

function determineGoogleKey(googleWebKey, googleTpIframeKey) {
    let key;
    if (iFrame) {
        if (isAgileMileSite) {
            key = _DEFAULT_GOOGLE_KEY;
        } else {
            key = googleTpIframeKey;
        }
    } else {
        key = googleWebKey;
    }

    return key;
}

const Routes = (props) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation(['menu']);
    const [prevLang, setPrevLang] = useState();
    const [mapsLoaded, setMapsLoaded] = useState(false);
    const {lang, member_id, user, brandLangs, google_web_key, google_tp_iframe_key} = useSelector(state => ({
        lang: state.Lang.lang,
        brandLangs: state.Lang.brandLangs,
        member_id: state.Auth.user?.member_id,
        user: state.Auth.user,
        google_web_key: state.Brand.google_web_key,
        google_tp_iframe_key: state.Brand.google_tp_iframe_key,
    }));

    useEffect(() => {
        if (lang !== prevLang) {
            setPrevLang(lang);
        }
    }, [lang]);

    useEffect(() => {
        if (i18n.language && brandLangs) {
            if (!brandLangs.includes(i18n.language)) {
                i18n.changeLanguage(brandLangs[0])
            } else {
                if (member_id > 0) {
                    dispatch(memberLangChangeRequested(i18n.language))
                } else {
                    dispatch(publicLangChangeRequested(i18n.language))
                }
            }
        }
    }, [i18n, member_id, brandLangs]);

    useEffect(() => {
        //check to change theme
        const prefersDarkMode = localStorage.getItem("prefersDarkMode");
        if (prefersDarkMode === "true") {
            dispatch(toggleTheme(true));
        } else if (prefersDarkMode === null) {
            //preference was never set, so check system pref
            const useDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (useDarkMode) {
                dispatch(toggleTheme(true));
            }
        }
    }, [])

    const getValidPaths = (arr) => {
        let validPaths = [];
        arr.forEach((item) => {
            if (item.path) {
                validPaths.push(item.path);
            }
        });
        return validPaths;
    }

    function handleMapsLoaded() {
        setMapsLoaded(true);
    }

    return (
        (lang && lang === prevLang) ? (
            <APIProvider
                apiKey={determineGoogleKey(google_web_key, google_tp_iframe_key)}
                language={lang}
                libraries={GOOGLE_MAPS_LIBRARIES}
                onLoad={handleMapsLoaded}
            >
                {
                    mapsLoaded ? (
                        <Router history={history}>
                            <Switch>
                                <Route
                                    path={getValidPaths(authProtectedFlattenRoutes)}
                                    exact={true}
                                >
                                    <VerticalLayout {...props} user={user}>
                                        <Switch>
                                            {authProtectedFlattenRoutes.map((route, index) => {
                                                return (
                                                    !route.children && (
                                                        <route.route
                                                            key={index}
                                                            path={route.path}
                                                            roles={route.roles}
                                                            exact={route.exact}
                                                            component={route.component}
                                                            name={t(route.name)}
                                                        />
                                                    )
                                                );
                                            })}
                                        </Switch>
                                    </VerticalLayout>
                                    <ScrollToTop/>
                                </Route>

                                <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
                                    <DefaultLayout {...props} >
                                        <Switch>
                                            {publicProtectedFlattenRoutes.map((route, index) => {
                                                return (
                                                    !route.children && (
                                                        <route.route
                                                            key={index}
                                                            path={route.path}
                                                            roles={route.roles}
                                                            exact={route.exact}
                                                            component={route.component}
                                                            name={t(route.name)}
                                                            includeLayout={route.includeLayout}
                                                        />
                                                    )
                                                );
                                            })}
                                        </Switch>
                                    </DefaultLayout>
                                    <ScrollToTop/>
                                </Route>
                            </Switch>
                        </Router>
                    ) : (<Loader/>)
                }
            </APIProvider>
        ) : (<Loader/>)
    );
};

export default Routes;
