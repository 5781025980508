/***********************************************
 *
 *  Global Constants
 *
 ***********************************************/
//Field min and max lengths
export const USERNAME_NAME_MIN = 6;
export const USERNAME_NAME_MAX = 16;
export const FIRST_NAME_MIN = 1;
export const FIRST_NAME_MAX = 32;
export const LAST_NAME_MIN = 1;
export const LAST_NAME_MAX = 32;
export const FULL_NAME_MAX = 64;
export const PASSWORD_MIN = 8;
export const PASSWORD_MAX = 128;
export const CONTACT_US_USERNAME_MAX = 16;
export const CONTACT_US_EMAIL_MAX = 64;
export const CONTACT_US_MESSAGE_MAX = 256;
export const LOCATION_NAME_MAX = 50;
export const RACE_TEAM_NICKNAME_MIN = 3;
export const RACE_TEAM_NICKNAME_MAX = 32;
export const PHONE_MAX = 15;
export const EXTENSION_MAX = 10;
export const EMAIL_MAX = 253;
export const VEHICLE_DESCRIPTION_MAX = 40;
export const ABOUT_ME_MAX = 256;
export const TRIP_PROFILE_NAME_MAX = 32;
export const ADDRESS_MAX = 64;
export const ZIPCODE_MAX = 5;
export const ORGANIZATION_NAME_MAX = 100;
export const ORGANIZATION_URL_MAX = 50;
export const HELP_SEARCH_STRING_MAX = 64;
//commute mileage rate
export const GSA_MILEAGE_RATE_URL = 'https://www.gsa.gov/travel/plan-book/transportation-airfare-rates-pov-rates-etc/privately-owned-vehicle-pov-mileage-reimbursement-rates';
export const MAYO_CLINIC_URL = 'https://www.mayoclinic.com/health/exercise/SM00109';
export const COMMUTE_CALCULATOR_DEFAULT_DISTANCE = 15 //15 miles one way
export const COMMUTE_CALCULATOR_DEFAULT_DAYS = 5 //5 days per week
//epa urls
export const EPA_WEB_SERVICES_URL = 'https://fueleconomy.gov/ws/rest/vehicle/';
export const EPA_VEHICLE_URL_FULL_SITE = 'https://fueleconomy.gov/feg/Find.do?action=sbs&id=';
export const EPA_VEHICLE_URL_MOBILE_SITE = 'https://fueleconomy.gov/m/m.do?action=vehicles&id=';
//this is the default type to pass for member contact requests
//We used to have more (ie. media = 2, partner request = 3, etc.), but we don't anymore. If we add other type of contact requests in the future we could add more types
export const CONTACT_US_SUPPORT = 1;
//Landing Page sections
export const LANDING_PAGE_HEADER_SECTION = 1;
export const LANDING_PAGE_NEWS_TICKER_SECTION = 2;
export const LANDING_PAGE_WELCOME_SECTION = 3;
export const LANDING_PAGE_ABOUT_SECTION = 4;
export const LANDING_PAGE_HIW_SECTION = 5;
export const LANDING_PAGE_FIND_RIDES_SECTION = 6;
export const LANDING_PAGE_REWARDS_SECTION = 7;
export const LANDING_PAGE_EVENTS_SECTION = 9;
export const LANDING_PAGE_CUSTOM_SECTION = 10;
export const LANDING_PAGE_FOOTER_SECTION = 11;
export const LANDING_PAGE_SECTION_STYLE_1 = 1;
export const LANDING_PAGE_SECTION_STYLE_2 = 2;
//unit types
export const UNIT_TYPE_IMPERIAL = 1;
export const UNIT_TYPE_METRIC = 2;
export const STATES = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "MD", "ME", "MA", "MI", "MN", "MS",
    "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WV", "WI", "WY"];
export const ZIP_CODE_MAX = 5;

export const LOCATION_TYPE_HOME = 1;
export const LOCATION_TYPE_ORGANIZATION = 2;
export const LOCATION_TYPE_REGULAR = 3;

export const MAP_INITIAL_ZOOM = 8;
export const DEFAULT_MAP_SINGLE_LOCATION_ZOOM = 17;

export const DEFAULT_MAP_MAX_ZOOM_MEMBERS = 14;
export const DEFAULT_MAP_MIN_ZOOM = 3;
export const DEFAULT_MAP_MAX_ZOOM = 18;


/* browser geolocation */
export const GEOLOCATION_ENABLE_HIGH_ACCURACY = true;      // high accuracy, not used for iOS
export const GEOLOCATION_MAXIMUM_AGE = 0;         // 0 = always get new location, not used for iOS
export const GEOLOCATION_TIMEOUT = 5000; // 5 seconds, not used by iOS
export const GEOLOCATION_MIN_ACCURACY = 300;       // meters
export const GEOLOCATION_MIN_ACCURACY_AFTER_RETRIES = 2500;       // 2,500 meters (~1.5 mi), after trying max number of times
export const GEOLOCATION_MAX_TRYS = 5;         // max number of retrys
export const GEOLOCATION_MAXIMUM_TIME = 10000; // 2 seconds
export const GEOLOCATION_DELAY_BEFORE_RETRY = 1000;  // 1 second delay before retrying

export const METERS_PER_MILE = 1609.34;

//ui stuff
export const SHOW_TOAST_TIME = 4000; //4 seconds

//photo limits
export const PHOTO_SIZE_LIMIT = 1048576 * 10; //in bytes; 1 Megabyte = 1048576 Bytes, this is 10 megabytes

export const PHOTO_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg'],
    'image/gif': ['.gif']
};

export const DAYS_OF_WEEK_DATA = [
    {
        value: 0,
        full_key: "calendar_day_sunday_full",
        one_letter_key: "calendar_day_sunday_1_letter",
        two_letter_key: "calendar_day_sunday_2_letter",
        three_letter_key: "calendar_day_sunday_3_letter"
    },
    {
        value: 1,
        full_key: "calendar_day_monday_full",
        one_letter_key: "calendar_day_monday_1_letter",
        two_letter_key: "calendar_day_monday_2_letter",
        three_letter_key: "calendar_day_monday_3_letter"
    },
    {
        value: 2,
        full_key: "calendar_day_tuesday_full",
        one_letter_key: "calendar_day_tuesday_1_letter",
        two_letter_key: "calendar_day_tuesday_2_letter",
        three_letter_key: "calendar_day_tuesday_3_letter"
    },
    {
        value: 3,
        full_key: "calendar_day_wednesday_full",
        one_letter_key: "calendar_day_wednesday_1_letter",
        two_letter_key: "calendar_day_wednesday_2_letter",
        three_letter_key: "calendar_day_wednesday_3_letter"
    },
    {
        value: 4,
        full_key: "calendar_day_thursday_full",
        one_letter_key: "calendar_day_thursday_1_letter",
        two_letter_key: "calendar_day_thursday_2_letter",
        three_letter_key: "calendar_day_thursday_3_letter"
    },
    {
        value: 5,
        full_key: "calendar_day_friday_full",
        one_letter_key: "calendar_day_friday_1_letter",
        two_letter_key: "calendar_day_friday_2_letter",
        three_letter_key: "calendar_day_friday_3_letter"
    },
    {
        value: 6,
        full_key: "calendar_day_saturday_full",
        one_letter_key: "calendar_day_saturday_1_letter",
        two_letter_key: "calendar_day_saturday_2_letter",
        three_letter_key: "calendar_day_saturday_3_letter"
    },
]

export const CALENDAR_EVENT_TYPE_TRIP = 1;
export const CALENDAR_EVENT_TYPE_HOLIDAY = 2;
export const CALENDAR_EVENT_TYPE_DAY_OFF = 3;
export const CALENDAR_EVENT_TYPE_LOADING = 4;

/* Mode identifiers */
export const ALL_MODES = 0;
export const NON_DRIVE_ALONE = 1;
export const RIDESHARE_DRIVER_MODE = 4;
export const RIDESHARE_RIDER_MODE = 5;
export const RIDESHARE_MODE = 18;
export const BIKE_MODE = 6;
export const WALK_MODE = 7;
export const TELECOMMUTE_MODE = 8;
export const COMP_MODE = 12;
export const TRANSIT_MODE = 13;
export const MULTIMODE_MODE = 14;
export const BROWN_BAG_MODE = 15;
export const DRIVE_ALONE_MODE = 16;
export const SCOOTER_MODE = 17;
//modes that can't be in a multimode trip
export const INVALID_MULTIMODE_MODES = [TELECOMMUTE_MODE, COMP_MODE, MULTIMODE_MODE, BROWN_BAG_MODE];

export const COMP_WEEK_4_40 = 440;
export const COMP_WEEK_9_80 = 980;

/* Default time for all trips is noon */
export const DEFAULT_TRIP_HOUR = 12;
export const DEFAULT_TRIP_MIN = 0;
export const DEFAULT_TRIP_SECONDS = 0;


/* Milliseconds per day */
export const MILLISECONDS_PER_DAY = 86400000;
export const MILLISECONDS_PER_HOUR = 3600000;
export const MILLISECONDS_PER_MINUTE = 60000;

//legal doc types
export const LEGAL_DOC_PRIVACY_POLICY_TYPE = 1;
export const LEGAL_DOC_TERMS_OF_SERVICE_TYPE = 2;
export const LEGAL_DOC_MEMBER_AGREEMENT_TYPE = 3;

//trip sync sources
export const TRIP_RECORD_SOURCE_STRAVA = 2;
export const TRIP_RECORD_SOURCE_LOVE_TO_RIDE = 8;
export const TRIP_RECORD_SOURCE_GARMIN = 9;
export const TRIP_RECORD_SOURCE_FITBIT = 10;
export const TRIP_RECORD_SOURCE_CARMA = 11;

//reward fulfillment statuses
export const FULFILLMENT_MOBILE = 3;
export const FULFILLMENT_USMAIL = 0;
export const FULFILLMENT_EMAIL_CODE = 1;
export const FULFILLMENT_EMAIL_ONLY = 2;
export const FULFILLMENT_STATUS_SENT = 3;
export const FULFILLMENT_STATUS_FAIL = 4;

//rewards
export const OFFER_TYPE_REWARD = 1;
export const OFFER_TYPE_DRAWING = 2;

export const REWARD_PROVIDER_US = 1001;
export const REWARD_PROVIDER_ENTERTAINMENT = 1002;
export const REWARD_TYPE_ATTRACTIONS = 1000;
export const REWARD_TYPE_HEALTH_BEAUTY = 1005;
export const REWARD_TYPE_MEMBERSHIPS = 1006;
export const REWARD_TYPE_RESTAURANTS = 1001;
export const REWARD_TYPE_SERVICES = 1002;
export const REWARD_TYPE_SHOPPING = 1003;
export const REWARD_TYPE_SPORTS = 1004;
export const REWARD_CATEGORY_FEATURED = 200;
export const REWARD_CATEGORY_DRAWING = 201;
export const REWARD_CATEGORY_DINING = 202;
export const REWARD_CATEGORY_ACTIVITIES = 203;
export const REWARD_CATEGORY_SHOPPING = 204;
export const REWARD_CATEGORY_SERVICES = 205;
export const REWARD_CATEGORY_ONLINE = 206;
export const REWARD_CATEGORY_IDS = [REWARD_CATEGORY_FEATURED, REWARD_CATEGORY_DRAWING, REWARD_CATEGORY_DINING, REWARD_CATEGORY_ACTIVITIES, REWARD_CATEGORY_SHOPPING, REWARD_CATEGORY_SERVICES, REWARD_CATEGORY_ONLINE];
export const REWARD_CATEGORIES = [
    {
        label: "rewards_textview_label_featured",
        icon: "mdi mdi-star-outline",
        id: REWARD_CATEGORY_FEATURED,
        validOfferTypes: [OFFER_TYPE_REWARD, OFFER_TYPE_DRAWING],
        matchingRewardTypes: [REWARD_TYPE_ATTRACTIONS, REWARD_TYPE_HEALTH_BEAUTY, REWARD_TYPE_MEMBERSHIPS, REWARD_TYPE_RESTAURANTS, REWARD_TYPE_SERVICES, REWARD_TYPE_SHOPPING, REWARD_TYPE_SPORTS],
    },
    {
        label: "rewards_textview_label_drawings",
        icon: "mdi mdi-wallet-giftcard",
        id: REWARD_CATEGORY_DRAWING,
        validOfferTypes: [OFFER_TYPE_DRAWING],
        matchingRewardTypes: [REWARD_TYPE_ATTRACTIONS, REWARD_TYPE_HEALTH_BEAUTY, REWARD_TYPE_MEMBERSHIPS, REWARD_TYPE_RESTAURANTS, REWARD_TYPE_SERVICES, REWARD_TYPE_SHOPPING, REWARD_TYPE_SPORTS],
    },
    {
        label: "rewards_textview_label_dining",
        icon: "mdi mdi-coffee-outline",
        id: REWARD_CATEGORY_DINING,
        validOfferTypes: [OFFER_TYPE_REWARD],
        matchingRewardTypes: [REWARD_TYPE_RESTAURANTS],
    },
    {
        label: "rewards_textview_label_activities",
        icon: "mdi mdi-ticket-outline",
        id: REWARD_CATEGORY_ACTIVITIES,
        validOfferTypes: [OFFER_TYPE_REWARD],
        matchingRewardTypes: [REWARD_TYPE_ATTRACTIONS, REWARD_TYPE_SPORTS],
    },
    {
        label: "rewards_textview_label_shopping",
        icon: "mdi mdi-cart-outline",
        id: REWARD_CATEGORY_SHOPPING,
        validOfferTypes: [OFFER_TYPE_REWARD],
        matchingRewardTypes: [REWARD_TYPE_SHOPPING, REWARD_TYPE_HEALTH_BEAUTY, REWARD_TYPE_MEMBERSHIPS],
    },
    {
        label: "rewards_textview_label_services",
        icon: "mdi mdi-screwdriver",
        id: REWARD_CATEGORY_SERVICES,
        validOfferTypes: [OFFER_TYPE_REWARD],
        matchingRewardTypes: [REWARD_TYPE_SERVICES],
    },
    {
        label: "rewards_textview_label_online",
        icon: "mdi mdi-monitor",
        id: REWARD_CATEGORY_ONLINE,
        validOfferTypes: [OFFER_TYPE_REWARD],
        matchingRewardTypes: [REWARD_TYPE_ATTRACTIONS, REWARD_TYPE_HEALTH_BEAUTY, REWARD_TYPE_MEMBERSHIPS, REWARD_TYPE_RESTAURANTS, REWARD_TYPE_SERVICES, REWARD_TYPE_SHOPPING, REWARD_TYPE_SPORTS],
    },
];
export const SORT_NEWEST = 10;
export const SORT_DISTANCE_FROM_HOME = 12;
export const SORT_DISTANCE_FROM_ORG = 13;
export const SORT_NAME_AZ = 14;
export const SORT_NAME_ZA = 15;
export const SORT_POINTS_LOW_HIGH = 16;
export const SORT_POINTS_HIGH_LOW = 17;
export const SORT_OLDEST = 21;
export const SORT_RECOMMENDED = 22;
export const SORT_OPTIONS = [
    {
        label: "rewards_criteria_textview_option_closest",
        value: SORT_RECOMMENDED,
    },
    {
        label: "rewards_criteria_textview_option_closest_to_home",
        value: SORT_DISTANCE_FROM_HOME,
    },
    {
        label: "rewards_criteria_textview_option_closest_to_org",
        value: SORT_DISTANCE_FROM_ORG,
    },
    {
        label: "rewards_criteria_textview_option_newest",
        value: SORT_NEWEST,
    },
    {
        label: "rewards_criteria_textview_option_oldest",
        value: SORT_OLDEST,
    },
    {
        label: "rewards_criteria_textview_option_name_a_z",
        value: SORT_NAME_AZ,
    },
    {
        label: "rewards_criteria_textview_option_name_z_a",
        value: SORT_NAME_ZA,
    },
    {
        label: "rewards_criteria_textview_option_pts_low_hi",
        value: SORT_POINTS_LOW_HIGH,
    },
    {
        label: "rewards_criteria_textview_option_pts_hi_low",
        value: SORT_POINTS_HIGH_LOW,
    },
];
export const FILTER_PRINTABLE = 1;
export const FILTER_MOBILE = 2;
export const FILTER_MAP = 3;
export const REWARDS_MODAL_EXPIRING = 1;
export const REWARDS_MODAL_MOBILE_ONLY = 2;
export const REWARDS_MODAL_SUCCESS_ENTERTAINMENT = 3;
export const REWARDS_MODAL_MAILING_ADDRESS = 4;
export const REWARDS_MODAL_EXPIRY_WARNING_MOBILE = 5;
export const DETAILS_SECTION = 0;
export const SURVEY_SECTION = 1;
export const DELIVERY_SECTION = 3;
export const REDEEMED_SECTION = 4;
export const REWARD_PROVIDER_ENTERTAINMENT_COUPON_SPINNING_CROWN = "/nuride/assets/retailer/10/1084/F108417.gif";

//leaderboard metrics
export const LEADERBOARD_METRIC_TEAM_SPIRIT = 1001;
export const LEADERBOARD_METRIC_GREENER_TRIPS = 1002;
export const LEADERBOARD_METRIC_NEW_MEMBERS = 1003;
export const LEADERBOARD_METRIC_TOTAL_MEMBERS = 1004;
export const LEADERBOARD_METRIC_CARPOOL_TRIPS = 1005;
export const LEADERBOARD_METRIC_VANPOOL_TRIPS = 1006;
export const LEADERBOARD_METRIC_RIDESHARE_TRIPS = 1007;
export const LEADERBOARD_METRIC_BIKE_TRIPS = 1008;
export const LEADERBOARD_METRIC_WALK_TRIPS = 1009;
export const LEADERBOARD_METRIC_MULTIMODE_TRIPS = 1010;
export const LEADERBOARD_METRIC_TELECOMMUTES = 1011;
export const LEADERBOARD_METRIC_COMPRESSED_WEEKS = 1012;
export const LEADERBOARD_METRIC_MILES_NOT_DRIVEN = 1013;
export const LEADERBOARD_METRIC_EMISSIONS_PREVENTED = 1014;
export const LEADERBOARD_METRIC_CALORIES_BURNED = 1015;
export const LEADERBOARD_METRIC_GALLONS_GAS_SAVED = 1016;
export const LEADERBOARD_METRIC_MONEY_SAVED = 1017;
export const LEADERBOARD_METRIC_TRANSIT_TRIPS = 1018;
export const LEADERBOARD_METRIC_PARTICIPANTS = 1019;
export const LEADERBOARD_METRIC_PART_RATE_REGISTERED = 1020;
export const LEADERBOARD_METRIC_REDUCED_CAR_TRIPS = 1021;
export const LEADERBOARD_METRIC_RIDESHARE_TRIPS_PCT = 1102;
export const LEADERBOARD_METRIC_CARPOOL_TRIPS_PCT = 1103;
export const LEADERBOARD_METRIC_VANPOOL_TRIPS_PCT = 1104;
export const LEADERBOARD_METRIC_TRANSIT_TRIPS_PCT = 1105;
export const LEADERBOARD_METRIC_TELECOMMUTES_PCT = 1106;
export const LEADERBOARD_METRIC_BIKE_TRIPS_PCT = 1107;
export const LEADERBOARD_METRIC_WALK_TRIPS_PCT = 1108;
export const LEADERBOARD_METRIC_COMPRESSED_WEEKS_PCT = 1109;
export const LEADERBOARD_METRIC_MULTIMODE_TRIPS_PCT = 1110;
export const LEADERBOARD_METRIC_PART_RATE_EMPLOYEES = 1111;
export const LEADERBOARD_METRIC_SCOOTER_TRIPS = 1112;
export const LEADERBOARD_METRIC_SCOOTER_TRIPS_PCT = 1113;
export const LEADERBOARD_METRIC_BROWN_BAGS = 1114;
export const LEADERBOARD_METRIC_BROWN_BAGS_PCT = 1115;
export const LEADERBOARD_METRIC_ACTIVE_MEMBERS = 1116;
export const USPS_SERVER = "https://secure.shippingapis.com/ShippingAPITest.dll?API=Verify&XML=";
export const USPS_USERID = "122NURID4918";

//create language object
const AM_createLanguageObject = (key, id) => {
    return {
        key: key,
        id: id
    }
}

/* Languages */
export const LANGUAGES = [AM_createLanguageObject("languages_american_sign_language", "ASL"),
    AM_createLanguageObject("languages_arabic", "Arabic"),
    AM_createLanguageObject("languages_chinese", "Chinese"),
    AM_createLanguageObject("languages_english", "English"),
    AM_createLanguageObject("languages_french", "French"),
    AM_createLanguageObject("languages_german", "German"),
    AM_createLanguageObject("languages_greek", "Greek"),
    AM_createLanguageObject("languages_hindi", "Hindi"),
    AM_createLanguageObject("languages_italian", "Italian"),
    AM_createLanguageObject("languages_japanese", "Japanese"),
    AM_createLanguageObject("languages_korean", "Korean"),
    AM_createLanguageObject("languages_polish", "Polish"),
    AM_createLanguageObject("languages_portuguese", "Portuguese"),
    AM_createLanguageObject("languages_russian", "Russian"),
    AM_createLanguageObject("languages_spanish", "Spanish"),
    AM_createLanguageObject("languages_tagalog", "Tagalog"),
    AM_createLanguageObject("languages_vietnamese", "Vietnamese")];


/* Trip syncing methods and sources */
export const TRIP_RECORD_METHOD_MANUALLY_RECORDED = 1;
export const TRIP_RECORD_METHOD_ACTIVELY_TRACKED = 2;
export const TRIP_RECORD_METHOD_PASSIVELY_TRACKED = 3;


export const LEADERBOARD_ORGANIZATIONS_ALL = 500;
export const LEADERBOARD_ORGANIZATIONS_XX_LARGE = 400;
export const LEADERBOARD_ORGANIZATIONS_X_LARGE = 350;
export const LEADERBOARD_ORGANIZATIONS_LARGE = 300;
export const LEADERBOARD_ORGANIZATIONS_MEDIUM = 200;
export const LEADERBOARD_ORGANIZATIONS_SMALL = 100;
export const LEADERBOARD_TEAMS = 50;
export const LEADERBOARD_INDIVIDUALS = 25;

export const PARTNER_STATUSES = [
    {id: 1, label: "Potential"},
    {id: 2, label: "Prospect"},
    {id: 3, label: "Partner"},
    {id: 4, label: "Unresponsive"},
    {id: 5, label: "Declined"},
    {id: 6, label: "Inactive"}
];

//special location types
export const SPECIAL_LOCATION_TYPE_PARK_RIDE = 1001;
export const SPECIAL_LOCATION_TYPE_BIKE_SHARE = 1002;
export const SPECIAL_LOCATION_TYPE_CAR_SHARE = 1003;
export const SPECIAL_LOCATION_TYPE_CAR_CHARGE = 1004;
export const SPECIAL_LOCATION_TYPE_HITCHING_POST = 1005;
export const SPECIAL_LOCATION_TYPE_BIKE_RACK = 1006;

export const US_NE_SW_COORDS = {
    ne: {
        lng: -65.932,
        lat: 48.555
    },
    sw: {
        lng: -124.852,
        lat: 21.0164
    }
}

export const US_COORDS_BOUNDS_STRING = "bounds%5B0%5D.ne.lng=-65.932&bounds%5B0%5D.ne.lat=48.555&bounds%5B0%5D.sw.lng=-124.852&bounds%5B0%5D.sw.lat=21.0164";

export const TRIP_PLANNER_MODE_CARPOOL = 1;
export const TRIP_PLANNER_MODE_VANPOOL = 2;
export const TRIP_PLANNER_MODE_TRANSIT = 3;
export const TRIP_PLANNER_MODE_BIKE = 4;
export const TRIP_PLANNER_MODE_WALK = 5;
export const TRIP_PLANNER_MODE_ASSISTED_RIDES = 6;

export const TRIP_TYPE_COMMUTE = 1;
export const TRIP_TYPE_SINGLE_TRIP = 2;
export const TRIP_TYPE_EVENT = 3;
export const TRANSIT_SOURCE_OTP = 1;
export const TRANSIT_SOURCE_GOOGLE = 2;


export const CARPOOLING_ROLE_EITHER = 1;
export const CARPOOLING_ROLE_RIDE_ONLY = 2;
export const CARPOOLING_ROLE_DRIVE_ONLY = 3;

export const OTP_TRANSIT_MODES = ["BUS", "TRANSIT", "TRAM", "SUBWAY", "RAIL", "CABLE_CAR", "FERRY", "GONDOLA", "FUNICULAR", "FLEX"];

function AM_createTimezone(id, name, std, dst, std_offset, dst_offset) {
    this.id = id;
    this.name = name;  // e.g. "Eastern Time"
    this.std = std;   // standard time abbreviation (e.g. "EST")
    this.dst = dst;   // daylight savings time abbreviation (e.g. "EDT")
    this.std_offset = std_offset;  // standard offset from UTC in hours
    this.dst_offset = dst_offset;  // daylight offset from UTC in hours
}

export const TIME_ZONES = [
    new AM_createTimezone(1, "Eastern Time", "EST", "EDT", -5, -4),
    new AM_createTimezone(3, "Central Time", "CST", "CDT", -6, -5),
    new AM_createTimezone(4, "Mountain Time", "MST", "MDT", -7, -6),
    new AM_createTimezone(5, "Mountain Time (Arizona)", "MST", "MST", -7, -7),
    new AM_createTimezone(6, "Pacific Time", "PST", "PDT", -8, -7),
    new AM_createTimezone(7, "Universal Time Coordinated", "UTC", "UTC", 0, 0)
];

export const DAYLIGHT_SAVINGS = {
    start: {
        month: 2,
        day: 14,
        hour: 2,
        minute: 0
    },
    end: {
        month: 10,
        day: 7,
        hour: 2,
        minute: 0
    }
};

export const AVG_VEHICLE_MPG = 22;
export const WALK_AVG_MPH = 2;
export const BIKE_AVG_MPH = 10;
export const LBS_CO2_PER_GALLON_GAS = 19.6;

export const BIKE_CALORIES_PER_HOUR_FEMALE = 292;
export const BIKE_CALORIES_PER_HOUR_MALE = 364;
export const BIKE_CALORIES_PER_HOUR_AVERAGE = 328;
export const WALK_CALORIES_PER_HOUR_FEMALE = 204;
export const WALK_CALORIES_PER_HOUR_MALE = 255;
export const WALK_CALORIES_PER_HOUR_AVERAGE = 230;

/* User's org email status */
export const EMAIL_STATUS_VERIFY_NOT_REQUIRED = 1;
export const EMAIL_STATUS_VERIFY_REQUIRED = 2;
export const EMAIL_STATUS_VERIFYING = 3;
export const EMAIL_STATUS_VERIFIED = 4;
export const WEB_SERVICES_STATUS_OK = 200;
export const WEB_SERVICES_QUMMUTE_ERROR = 500;

export const SOCIAL_MEDIA_OPTIONS = [
    {name: "Facebook", icon: "uil-facebook-f"},
    {name: "Twitter", icon: "bi bi-twitter-x"},
    {name: "Instagram", icon: "uil-instagram"},
    {name: "YouTube", icon: "uil-youtube"},
    {name: "LinkedIn", icon: "uil-linkedin"},
    {name: "TikTok", icon: "bi bi-tiktok"}
];

//volunteer profile statuses
export const VOLUNTEER_PROFILE_STATUS_ACTIVE = 1;
export const VOLUNTEER_PROFILE_STATUS_UPCOMING = 2;
export const VOLUNTEER_PROFILE_STATUS_EXPIRED = 3;

export const RACE_STATUS_NOT_AVAILABLE = 1;
export const RACE_STATUS_NOT_PARTICIPATING = 2;
export const RACE_STATUS_PARTICIPATING = 3;
export const EVENT_STATUS_ACTIVE = 'active';
export const EVENT_STATUS_PAST = 'past';
export const RACE_TYPE_ORG = 'global_textview_label_organization';
export const RACE_TYPE_INDIVIDUAL = 'global_textview_label_individual';
export const RACE_TYPE_TEAM = 'global_textview_label_team';
export const RACE_TYPES = [RACE_TYPE_ORG, RACE_TYPE_TEAM, RACE_TYPE_INDIVIDUAL];

// Gender
export const MEMBER_GENDER_FEMALE = "F";
export const MEMBER_GENDER_MALE = "M";
export const MEMBER_GENDER_NON_BINARY = "NB";
export const MEMBER_GENDER_PREFER_NOT_TO_SAY = "P";
export const MEMBER_GENDER_UNSET = "";
export const MEMBER_GENDER_ALL = "MF";
export const MEMBER_SMOKER_YES = "Y"
export const MEMBER_SMOKER_NO = "N";
export const MEMBER_SMOKER_ALL = "YN";
export const MEMBER_COMMUTER = 2;
export const MEMBER_NON_COMMUTER = 1;
export const MEMBER_COMMUTE_UNSET = 0;

/* Minimum trip length */
export const MIN_TRIP_LENGTH_IN_FEET = 150;
export const MIN_TRIP_LENGTH_IN_YARDS = 50;
export const MIN_TRIP_LENGTH_IN_METERS = 45.72;
export const MIN_TRIP_LENGTH_IN_MILES = 0.0284;

export const TRIP_STAT_POINTS = 1;
export const TRIP_STAT_MILES = 2;
export const TRIP_STAT_GAS = 3;
export const TRIP_STAT_SAVINGS = 4;
export const TRIP_STAT_CALORIES = 5;
export const TRIP_STAT_EMISSIONS = 6;

export const BONUS_POINTS_AWARD_TYPE = 1;
export const REDEMPTION_REVERSAL_AWARD_TYPE = 2;

export const QUMMUTE_CLIENT_TYPE_WEBSITE = 1;

export const MODE_TYPE_TRIPS = "trips_summary_menu_trips";
export const MODE_TYPE_MILES = "trips_summary_menu_miles";
export const MODE_TYPE_CALORIES = "trips_summary_menu_calories"

// Error codes from problems saving trip
export const TRIPPROFILE_INVALID_STARTDATE = 702;
export const RECURRING_TRIP_SAME_DAY = 704;
export const TELECOMMUTE_EXIST = 800;
export const COMPRESSED_EXIST = 801;
export const BROWN_BAG_EXIST = 802;
export const NO_TELE_BROWN_BAG = 804;
export const NO_BROWN_BAG_TELE = 805;
export const AUTO_RECORD_UNEDITABLE = 900;

//events and destinations in trip planner
export const EVENT_TYPE = 10;
export const DESTINATION_TYPE = 11;

export const RECAPTCHA_BYPASS_TOKEN = "c94d99bf-87b3-4dda-bdbd-0bf9384cd2d9";

export const GO_PASS_AGENCIES = {
    google_names: ['Dallas Area Rapid Transit', 'DCTA', 'Trinity Metro', 'STAR Transit'],
    iOS_store: 'https://apps.apple.com/us/app/gopass/id673970506',
    android_store: 'https://play.google.com/store/apps/details?id=dk.unwire.projects.dart',
    state: 'TX'
};

export const BENEFIT_CALORIES = "calories";
export const BENEFIT_SAVINGS = "savings";
export const BENEFIT_EMISSIONS = "emissions";
export const BENEFIT_GAS = "gas";
export const BENEFIT_MILES = "miles";
export const BENEFIT_REWARDS = "rewards";
export const BENEFIT_POINTS = "points";

export const PASSWORD_RESET_INVALID_EMAIL_ERROR = 101;
export const PASSWORD_RESET_ACCOUNT_LOCKED_ERROR = 102;
export const PASSWORD_RESET_OTHER_ERROR = 103;
export const EMAIL_REGEX = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const GOOGLE_MAPS_LIBRARIES = ['places', 'maps', 'routes', 'marker', 'geocoding', 'core', 'geometry'];

export const ASSISTED_RIDES_FILTER_OPTIONS = {
    assisted_trip_type: [
        {
            label: "trip_planner_assisted_rides_filter_label_all_trips",
            value: -1,
        },
        {
            label: "trip_planner_assisted_rides_filter_label_general",
            value: 0,
            keyName: "general"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_medical",
            value: 1,
            keyName: "medical"
        }
    ],
    assisted_rider_type: [
        {
            label: "trip_planner_assisted_rides_filter_label_all_riders",
            value: -1
        },
        {
            label: "trip_planner_assisted_rides_filter_label_older_adults",
            value: 0,
            keyName: "older_adults"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_disabled",
            value: 1,
            keyName: "with_disabilities"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_veterans",
            value: 2,
            keyName: "veterans"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_youth",
            value: 3,
            keyName: "youth"
        }
    ],
    accessibility_options: [
        {
            label: "trip_planner_assisted_rides_filter_label_power_wheelchair",
            value: 0,
            keyName: "power_wheelchair"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_wheelchair",
            value: 1,
            keyName: "standard_wheelchair"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_mobility_device",
            value: 2,
            keyName: "mobility_device"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_mobility_service_animal",
            value: 3,
            keyName: "service_animals"
        },
        {
            label: "trip_planner_assisted_rides_filter_label_mobility_service_travel_aides",
            value: 4,
            keyName: "travel_aides"
        }
    ]
}

export const ASSISTED_RIDES_DEFAULT_FILTERS = {
    assisted_trip_type: {...ASSISTED_RIDES_FILTER_OPTIONS.assisted_trip_type[0]},
    assisted_rider_type: {...ASSISTED_RIDES_FILTER_OPTIONS.assisted_rider_type[0]},
    accessibility_options: null,
    has_fees: true,
}

